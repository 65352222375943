p {
    margin-block-start: .25em;
    margin-block-end: .25em;
}

body {
    text-align: center;
}

.seats {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.seat {
    width: 1200px;
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.hand {
    width: 512px;
    height: 60px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.seatActivePlay {
    width: 292px;
    height: 60px;
    border: 1px dashed lightgray;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px;

}

.horizontalFlexbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.seatActivePlay.winning {
    background-color: rgb(241, 255, 234);
    border: 1px solid rgb(101, 143, 81);
}

.phoenixRankExplanation {
    font-size: 24px;
    margin: 7px;
    font-weight: bold;
    color: rgb(202, 11, 74)
}

.card {
    width: 32px;
    height: 48px;
    border: none;
    margin: 2px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: bold;
    letter-spacing: -0.1em;
    text-indent: -0.1em;
    background: none;
    position: relative;
    z-index: 1;
}

.cardBackground {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fcfcfc;
    z-index: 1;
    border: 1px solid black;
    border-radius: inherit;
}

.cardMarkBottom, .cardMarkLeft, .cardMarkRight {
    position: absolute;
    pointer-events: none;
    z-index: 0;
}

.card > *:not(.cardBackground):not([class^="cardMark"]) {
    position: relative;
    z-index: 2;
}

.align_right {
    height: 32px;
    width: 48px;
    flex-direction: row;
}

.align_left {
    height: 32px;
    width: 48px;
    flex-direction: row-reverse;
}

.cardTextOnly {
    font-weight: bold;
}

.selectedCard {
    margin-bottom: 24px;
}

.card:hover .cardBackground {
    background-color: #ddd;
    border: 2px solid black;
}

.cardText {
    display: flex;
    justify-content: center;
    align-items: center;
}

.suitSymbol {
    font-size: 15px;
    font-weight: normal;
    margin-top: -3px;
    margin-bottom: 4px;
    display: flex;
    justify-content: center;
}

.rightSuitSymbol {
    font-size: 15px;
    font-weight: normal;
    margin-left: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.leftSuitSymbol {
    font-size: 15px;
    font-weight: normal;
    margin-right: 6px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.suitBlack {
    color: rgb(31, 31, 31);
}

.suitBlue {
    color: rgb(42, 67, 211);
}

.suitGreen {
    color: rgb(16, 151, 16);
}

.suitRed {
    color: rgb(136, 14, 30);
}

.suitSpecial {
    color: rgb(0, 0, 0);
}

.cardSmall {
    font-size: 11px;
    padding: 1px;
    height: 14px;
    width: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    border-radius: 2px;
}

.suitBlack.cardSmall {
    color: white;
    background-color: rgb(31, 31, 31);
}

.suitBlue.cardSmall {
    color: white;
    background-color: rgb(42, 67, 211);
}

.suitGreen.cardSmall {
    color: white;
    background-color: rgb(16, 151, 16);
}

.suitRed.cardSmall {
    color: white;
    background-color: rgb(136, 14, 30);
}

.suitSpecial.cardSmall {
    color: black;
    background-color:#fff6c1;
}

.suitSpecial.card .cardBackground {
    background-color: #fff6c1;
}

.suitSpecial.card:hover .cardBackground {
    background-color: #dacd89;
}

.suitNone .cardBackground {
    background-color: #ffffff;
    opacity: 1;
    background-size: 7px 7px;
    /* diagonal crossing lines */
    background-image: repeating-linear-gradient(45deg, #d3d3d3 0, #d3d3d3 0.7000000000000001px, #ffffff00 0, #ffffff00 50%), 
                     repeating-linear-gradient(135deg, #d3d3d3 0, #d3d3d3 0.7000000000000001px, #ffffff 0, #ffffff 50%);
}

.overlayCardBack .cardBackground {
    background-color: #f3f3f3;
}

.suitNone {
    color: rgb(0, 0, 0);
}

.unknown {
    pointer-events: none;
}

.unknown .cardBackground {
    background-color: #a9d7ec;
}

.left_trade {
    background-image: url('../assets/arrow-left.png');
    background-size: 100%;
}

.center_trade {
    background-image: url('../assets/arrow-straight.png');
    background-size: 100%;
}

.right_trade {
    background-image: url('../assets/arrow-right.png');
    background-size: 100%;
}

.left_trade .cardBackground {
    background-image: url('../assets/arrow-left.png');
    background-size: 100%;
}

.center_trade .cardBackground {
    background-image: url('../assets/arrow-straight.png');
    background-size: 100%;
}

.right_trade .cardBackground {
    background-image: url('../assets/arrow-right.png');
    background-size: 100%;
}

.pass {
    width: 50px;
    height: 32px;
    margin: 2px;
    border-radius: 5px;
    color: rgb(0, 0, 0);
    background-color: lightgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

.nextHandButton {
    margin: 12px;
    padding: 10px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    background-color: gold;
    font-weight: bold;
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}
.nextHandButton:hover {
    background-color: #ffe657;
}

.cancelTradeButton {
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    background-color: rgb(245, 116, 94);
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}
.cancelTradeButton:hover {
    background-color: rgb(252, 149, 131);
}

.playCardsButton {
    width: 50px;
    height: 50px;
    margin: 8px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    background-color: gold;
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

.playCardsButton:hover {
    background-color: #ffe657;
}
.playCardsButton:disabled {
    background-color: lightgray;
    color: gray;
}

.easyButton {
    position: relative;
}

.easyButton::after {
    content: "";
    left: -8px;
    right: -8px;
    bottom: -8px;
    top: -8px;
    position: absolute;
}

.exitReplayButton {
    width: 100px;
    height: 50px;
    margin: 8px;
    border-radius: 8px;
    color: #fff;
    background-color: #4040bf;
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

.exitReplayButton:hover {
    background-color: #6666cc;
}

.exitHypoButton {
    width: 100px;
    height: 50px;
    margin: 8px;
    border-radius: 8px;
    color: #fff;
    background-color: #d227c6;
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

.exitHypoButton:hover {
    background-color: #d062d7;
}

.suggestedPlayButton {
    min-width: 40px;
    height: 35px;
    margin: 8px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    background-color: gold;
    border: 1px solid darkgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

.suggestedPlayButton:hover {
    background-color: #ffe657;
}

.giveDragonCenter {
    width: 35px;
    height: 50px;
    margin: 4px;
    border-radius: 8px;
    color: rgb(0, 0, 0);
    border: 0px dashed darkgray;
    font-size: 24px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.wish-select {
    display:inline-flex;
    background:#f5f5f5;
    border:1px solid #ccc;
    border-radius:4px;
    user-select: none;
}
.wish-select > input + label {
    display:inline-block;
    margin-bottom:0;
    padding:5px 7px;
    cursor:pointer;
    min-width: 16px;
}
.wish-select > input:checked + label {
    background: #2f8cb6;
    color: white;
}
.wish-select > input + label:not(:last-of-type) {
    border-right:1px solid #ccc;
}
.wish-select > input:not(:checked) + label:hover {
    background:#5dafd5;
}
.wish-select > input[type='radio'] {
    display:none;
}

/* need 1272 x 680 */
/*
    0.9	1144	611
    0.8	1017	543
    0.7	890	475
    0.6	763	407
    0.5	635	339
    0.45	572	305
    0.4	508	271
    0.35	445	237
    0.3	381	203
    0.25	317	169
    0.2	254	135
*/
@media (max-width: 1271px), (max-height: 679px) {
    #root {
        zoom: .9;
    }
  }
@media (max-width: 1144px), (max-height: 611px) {
    #root {
        zoom: .8;
    }
  }
@media (max-width: 1017px), (max-height: 543px) {
    #root {
        zoom: .7;
    }
  }
@media (max-width: 890px), (max-height: 475px) {
    #root {
        zoom: .6;
    }
  }
@media (max-width: 763px), (max-height: 407px) {
    #root {
        zoom: .5;
    }
  }
@media (max-width: 635px), (max-height: 339px) {
    #root {
        zoom: .45;
    }
  }
@media (max-width: 572px), (max-height: 305px) {
    #root {
        zoom: .4;
    }
  }
@media (max-width: 508px), (max-height: 271px) {
    #root {
        zoom: .35;
    }
  }

@media (max-width: 445px), (max-height: 237px) {
    #root {
        zoom: .3;
    }
  }
@media (max-width: 381px), (max-height: 203px) {
    #root {
        zoom: .25;
    }
  }
@media (max-width: 317px), (max-height: 169px) {
    #root {
        zoom: .2;
    }
  }

.cardMarkBottom {
    position: absolute;
    bottom: -4px;
}

.cardMarkLeft {
    position: absolute;
    left: -4px;
}

.cardMarkRight {
    position: absolute;
    right: -4px;
}

.cardMarkDot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    border: 1px solid #333;
    background-color: #fcfcfc;
}

.offsetUp {
    top: 3px;
}

.offsetDown {
    bottom: 3px;
}

.offsetLeft {
    left: 3px;
}

.offsetRight {
    right: 3px;
}

.markTeamRed {
    background-color: #f3a17a;
}

.markTeamBlue {
    background-color: #b5b5e3;
}

.cardGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #9ecfe6;
    border-radius: 5px;
    width: 230px;
    padding: 10px;
    margin: 0px 12px;
    gap: 1px;
}

.cardGridRow {
    display: flex;
    gap: 1px;
    height: 16px;
    align-items: center;
    justify-content: center;
}

.cardGridCell {
    display: flex;
    justify-content: center;
    align-items: center;
}


.faded {
    opacity: 0.2;
}

